<template>
    <div>
        <hotels-component/>
        <footer-component/>
    </div>
</template>

<script>
    import HotelsComponent from "@/components/hotels/HotelsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Hotels",
        title: "Hoteles | Turismo BC",
        components: {
            FooterComponent, HotelsComponent
        }
    }
</script>

<style scoped>

</style>